import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';
import { getManagers, updateManager, deleteManager, addManager } from '../api'; // Предполагаем, что API методы уже реализованы
import { Space, Table, Tag, Modal, Button, Form, Input, message, Checkbox, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

export default function Managers() {
  const { data = [], refetch } = useQuery({
    queryKey: ['managers'],
    queryFn: getManagers,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: true,
  });

  const queryClient = useQueryClient();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false); // Флаг для режима добавления
  const [selectedManager, setSelectedManager] = useState(null);
  const [form] = Form.useForm();

  const { mutate: mutateAdd } = useMutation(addManager, {
    onSuccess: () => {
      message.success('Менеджер успешно добавлен!');
      queryClient.invalidateQueries(['managers']);
      setIsModalVisible(false);
    },
    onError: () => {
      message.error('Ошибка при добавлении менеджера.');
    },
  });

  const { mutate: mutateUpdate } = useMutation(updateManager, {
    onSuccess: () => {
      message.success('Менеджер успешно обновлен!');
      queryClient.invalidateQueries(['managers']);
      setIsModalVisible(false);
    },
    onError: () => {
      message.error('Ошибка при обновлении менеджера.');
    },
  });

  const { mutate: mutateDelete } = useMutation(deleteManager, {
    onSuccess: () => {
      message.success('Менеджер удалён!');
      queryClient.invalidateQueries(['managers']);
      setIsModalVisible(false);
    },
    onError: () => {
      message.error('Ошибка при удалении менеджера.');
    },
  });

  const dataTable = Array.isArray(data)
    ? data.map((el) => ({
        key: el._id,
        id: el._id,
        isActive: el.isActive,
        fullName: el.fullName,
        email: el.email,
        phone: el.phone,
        position: el.position,
        roles: el.roles,
        color: el.isActive ? 'green' : 'red',
      }))
    : [];

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Статус',
      dataIndex: 'isActive',
      key: 'isActive',
      width: '5%',
      ...getColumnSearchProps('isActive'),
      render: (isActive) => <Tag color={isActive ? 'green' : 'red'}>{isActive ? 'Активен' : 'Выключен'}</Tag>,
    },
    {
      title: 'Имя',
      dataIndex: 'fullName',
      key: 'fullName',
      width: '20%',
      ...getColumnSearchProps('fullName'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '30%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: '30%',
      ...getColumnSearchProps('phone'),
    },
    {
      title: 'Роли',
      dataIndex: 'roles',
      key: 'roles',
      width: '30%',
      render: (roles) => (
        <span>
          {roles.map((role) => (
            <Tag key={role} color={role === 'admin' ? 'green' : 'blue'}>
              {role === 'admin' ? 'Администратор' : 'Модератор'}
            </Tag>
          ))}
        </span>
      ),
    },
  ];

  const handleRowClick = (record) => {
    setIsAddMode(false);
    setSelectedManager(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleEdit = () => {
    mutateUpdate({ ...selectedManager, ...form.getFieldsValue() });
  };

  const handleAdd = () => {
    mutateAdd(form.getFieldsValue());
  };

  return (
    <>
      <div style={{ margin: 16 }}>
        <span>Всего менеджеров: {dataTable.length}</span>
        <Button
          type="primary"
          style={{ marginLeft: 16 }}
          onClick={() => {
            setIsAddMode(true);
            form.resetFields();
            setIsModalVisible(true);
          }}
        >
          Добавить менеджера
        </Button>
      </div>
      <Table columns={columns} dataSource={dataTable} onRow={(record) => ({ onClick: () => handleRowClick(record) })} />
      <Modal
        title={isAddMode ? 'Добавить менеджера' : 'Редактировать менеджера'}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          isAddMode ? (
            <Button key="add" type="primary" onClick={handleAdd}>
              Добавить
            </Button>
          ) : (
            <>
              <Button key="delete" danger onClick={() => mutateDelete(selectedManager.key)}>
                Удалить
              </Button>
              <Button key="save" type="primary" onClick={handleEdit}>
                Сохранить
              </Button>
            </>
          ),
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            Отмена
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Имя" name="fullName" rules={[{ required: true, message: 'Введите имя' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Введите email' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Телефон" name="phone" rules={[{ required: true, message: 'Введите телефон' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Должность" name="position" rules={[{ required: true, message: 'Введите должность' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="isActive" valuePropName="checked">
            <Checkbox>Активный</Checkbox>
          </Form.Item>
          <Form.Item label="Роли" name="roles" rules={[{ required: true, message: 'Выберите роли' }]}>
            <Select
              mode="multiple"
              options={[
                { value: 'admin', label: 'Администратор' },
                { value: 'moderator', label: 'Модератор' },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
