const customTheme = {
  // '@screen-xs': 'var(--antd-proximity-breaking-xsmall)',
  // '@screen-sm': 'var(--antd-proximity-breaking-small)',
  // '@screen-md': 'var(--antd-proximity-breaking-medium)',
  // '@screen-xl': 'var(--antd-proximity-breaking-xlarge)',
  // '@screen-xxl': 'var(--antd-proximity-breaking-xxlarge)',
  // 'screen-xxl': '1920px',
  // 'screen-xl': '1440px',
  // 'screen-md': '768px',
  // 'screen-sm': '576px',
  // 'screen-xs': '375px',
  // screenXSMin: 375,
  // screenXS: 375,
  // screenXSMax: 575,
  // screenSMMin: 576,
  // screenSM: 576,
  // screenSMMax: 767,
  // screenMDMin: 768,
  // screenMD: 768,
  // screenMDMax: 991,
  // screenLGMin: 992,
  // screenLG: 992,
  // screenLGMax: 1199,
  // screenXLMin: 1200,
  // screenXL: 1200,
  // screenXLMax: 1599,
  // screenXXLMin: 1700,
  // screenXXL: 1700,
  // screenXXLMax: 1920,
  // node_modules/antd/es/config-provider/context.d.ts
  token: {
    screenXL: 1281,
    screenXXL: 1681,
    screenLG: 1025,
    screenMd: 768,
    screenSM: 576,
    screenXS: 375,
    screenXSMin: 300,
    colorPrimary: '#a6d05d',
    colorSuccess: '#8d8dcd',
    fontFamily: 'Montserrat',
    borderRadius: 12,
  },
  components: {
    Layout: {
      colorBgTrigger: '#1d2c4a',
      colorBgHeader: '#1d2c4a',
    },
    // Menu: {
    //   fontFamily: 'Raleway',
    //   colorItemText: '#fff',
    //   itemMarginInline: 0,
    //   radiusItem: 12,
    // },
    Button: {
      borderRadius: 8,
      colorBgContainerDisabled: '#F0F0F0',
      colorTextDisabled: '#8C8C8C',
      colorBorder: '#BFBFBF',
    },
    Checkbox: {
      colorBorder: '#8E9FBF',
    },
    Pagination: {
      borderRadius: 6,
    },
    Skeleton: {
      borderRadiusSM: 12,
      colorFill: '#1d2c4a',
      colorFillContent: '#1d2c4a',
    },
    Table: {
      colorBorderSecondary: '#62769d',
      colorFillAlter: '#405174',
      colorTextHeading: '#fff',
      colorIcon: '#fff',
      colorIconHover: '#fff',
    },
    Select: {
      borderRadius: 8,
      colorBorder: '#8E9FBF',
      colorTextPlaceholder: '#62769D',
      colorPrimary: '#2F3F5E',
      padding: 0,
      paddingBlock: 8,
      margin: 0,
    },
    Input: {
      borderRadius: 8,
      colorBorder: '#8E9FBF',
      colorTextPlaceholder: '#62769D',
      colorPrimary: '#2F3F5E',
    },
  },
}

export { customTheme }
