import axios from 'axios';
export const API_BASE_URL = 'https://svamgroup.ru/api';
export const getData = async () => {
  const token = localStorage.getItem('token');

  const { data } = await axios.get(`${API_BASE_URL}/orders/client/analytics?id=62cad21f5443160f6327f58a`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return data;
};

export const getUsers = async () => {
  const token = localStorage.getItem('token'); // Получение токена из локального хранилища

  try {
    const { data } = await axios.get(`${API_BASE_URL}/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    // Обработка ошибки
    throw error;
  }
};

export const getManagers = async () => {
  const token = localStorage.getItem('token'); // Получение токена из локального хранилища

  try {
    const { data } = await axios.get(`${API_BASE_URL}/managers`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return data?.data;
  } catch (error) {
    // Обработка ошибки
    throw error;
  }
};

export const addManager = async (managerData) => {
  const token = localStorage.getItem('token'); // Получение токена из локального хранилища

  try {
    const response = await axios.post(
      `${API_BASE_URL}/managers/new`,
      { manager: managerData },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data; // Возврат данных из ответа
  } catch (error) {
    // Обработка ошибки
    const errorMessage = error.response?.data?.message || 'Ошибка при добавлении менеджера.';
    console.error('Ошибка в addManager:', errorMessage);
    throw new Error(errorMessage);
  }
};

export const updateManager = async (manager) => {
  if (!manager?.id) {
    throw new Error('ID менеджера не указан');
  }

  const token = localStorage.getItem('token'); // Получение токена из локального хранилища

  try {
    /* update manager by id */
    const { data } = await axios.patch(
      `${API_BASE_URL}/managers/${manager.id}`, // Используем manager.id
      { ...manager }, // Передаем поля напрямую, без вложенности
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );

    return data?.manager; // Ожидаем, что сервер вернет обновленного менеджера под ключом "manager"
  } catch (error) {
    console.error('Ошибка при обновлении менеджера:', error.message);
    throw error;
  }
};

export const deleteManager = async (id) => {
  if (!id) {
    throw new Error('ID менеджера не указан');
  }

  const token = localStorage.getItem('token'); // Получение токена из локального хранилища

  try {
    const { data } = await axios.delete(`${API_BASE_URL}/managers/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return data?.message; // Возвращаем сообщение о результате операции
  } catch (error) {
    console.error('Ошибка при удалении менеджера:', error.message);
    throw error;
  }
};

export const actualUser = async () => {
  const token = localStorage.getItem('token'); // Получение токена из локального хранилища

  try {
    const { data } = await axios.get(`${API_BASE_URL}/auth/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    // Обработка ошибки
    throw error;
  }
};

export const getClients = async () => {
  const token = localStorage.getItem('token'); // Получение токена из локального хранилища

  try {
    const { data } = await axios.get(`${API_BASE_URL}/clients`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    // Обработка ошибки
    throw error;
  }
};

export const getProducts = async () => {
  const token = localStorage.getItem('token'); // Получение токена из локального хранилища

  try {
    const { data } = await axios.get(`${API_BASE_URL}/products`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    // Обработка ошибки
    throw error;
  }
};
export const getOrders = async () => {
  const token = localStorage.getItem('token'); // Получение токена из локального хранилища

  try {
    const { data } = await axios.get(`${API_BASE_URL}/orders`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    // Обработка ошибки
    throw error;
  }
};

export const addUser = async (userData) => {
  try {
    const response = await axios.post('ваш_путь_к_api_для_добавления_пользователя', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
