import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Auth from './pages/Auth';
import Users from './pages/Users';
import Managers from './pages/Managers';
import Navbar from './layout/Navbar';
import Clients from './pages/Clients';
import { ConfigProvider } from 'antd';
import { customTheme } from './styles';
import Products from './pages/Products';
import Orders from './pages/Orders';
import Report from './pages/Report';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { actualUser } from './api';
import Main from './pages/Main';
import axios from 'axios';
import { CodeSandboxCircleFilled } from '@ant-design/icons';

const queryClient = new QueryClient();

const API_BASE_URL = 'https://svamgroup.ru/api';

const App = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state

  useEffect(() => {
    const checkUserAuthentication = async () => {
      const token = localStorage.getItem('token');

      try {
        const { data } = await axios.get(`${API_BASE_URL}/auth/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setUser(data);
      } catch (error) {
        navigate('/auth'); // Redirect to the login page if the user doesn't exist
      } finally {
        setIsLoading(false); // Set isLoading to false after authentication check
      }
    };

    checkUserAuthentication();
  }, [navigate]);

  if (isLoading) {
    return null; // Render nothing until the authentication check is complete
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={customTheme}>
        {user ? (
          <>
            <Navbar />
            <Routes>
              <Route path="/" element={<Clients />} />
              {/* <Route path="/clients" element={<Clients />} /> */}
              <Route path="/users" element={<Users />} />
              <Route path="/managers" element={<Managers />} />
              {/* <Route path="/orders" element={<Orders />} />
              <Route path="/products" element={<Products />} />
              <Route path="/report" element={<Report />} /> */}
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path="/auth" element={<Auth />} />
          </Routes>
        )}
      </ConfigProvider>
    </QueryClientProvider>
  );
};

export default App;
