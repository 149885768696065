import React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Form, Input } from 'antd'
import axios from 'axios'
import { actualUser, API_BASE_URL } from '../api'
import { useNavigate } from 'react-router-dom'

export default function Auth() {
  const navigate = useNavigate()
  const { mutate } = useMutation(
    (formData) => {
      return axios.post(`${API_BASE_URL}/auth/login/admin`, formData)
    },
    {
      onSuccess: (data) => {
        localStorage.setItem('token', data.data.token) // Сохранение токена в локальное хранилище
        // console.log('Success', data.data.token)
        navigate('/')
        // Логика после успешного выполнения запроса
      },
      onError: (error) => {
        console.log('Error', error)
        // Логика при ошибке выполнения запроса
      },
    }
  )

  const onFinish = (values) => {
    mutate(values) // Вызов мутации с данными формы
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Личный кабинет
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '50px',
        }}
      >
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 800,
            alignItems: 'center',
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Введите email!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Пароль"
            name="password"
            rules={[
              {
                required: true,
                message: 'Введите пароль!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              Войти
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
